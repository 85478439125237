import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight, PlusCircle, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { animated, useTransition } from 'react-spring';

const SelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    height: 1px;
    background: ${props => props.theme.gradients.pink};
    box-shadow: ${props => props.theme.neonShadow.pink};
  }
`;

const GameButtonContainer = styled(animated.div)`
  display: flex;
  align-items: center;
  background: ${props => props.theme.cardBackground};
  padding: 5px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const GameButton = styled.button`
  background-color: ${props => props.$active ? 'transparent' : 'transparent'};
  color: ${props => props.$active ? props.theme.primary : props.theme.textSecondary};
  border: ${props => props.$active ? `1px solid ${props.theme.primary}` : '1px solid transparent'};
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 4px;
  cursor: ${props => props.$available ? 'pointer' : 'not-allowed'};
  transition: all 0.3s ease;
  opacity: ${props => props.$available ? 1 : 0.5};
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: ${props => props.theme.font};
  font-size: 0.9rem;

  ${props => props.$active && `
    box-shadow: ${props.theme.neonShadow.pink};

    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 6px;
      background: ${props.theme.gradients.pink};
      z-index: -1;
      opacity: 0.5;
    }
  `}

  &:hover {
    ${props => props.$available && !props.$active && `
      border-color: ${props.theme.secondary};
      box-shadow: ${props.theme.neonShadow.blue};
      color: ${props.theme.secondary};
    `}
  }

  &:focus {
    outline: none;
    box-shadow: ${props => props.theme.neonShadow.purple};
  }
`;

const UnavailableIcon = styled(AlertCircle)`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${props => props.theme.error};
  filter: drop-shadow(0 0 5px ${props => props.theme.error});
`;

const IconButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border-radius: 50%;

  &:not(:disabled):hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: ${props => props.theme.neonShadow.blue};
    color: ${props => props.theme.secondary};
  }

  &:disabled {
    color: ${props => props.theme.textSecondary};
    cursor: not-allowed;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:not(:disabled):hover svg {
    transform: scale(1.2);
  }
`;

const AddProfileButton = styled(IconButton)`
  color: ${props => props.theme.success};
  margin-left: 10px;

  &:hover {
    box-shadow: ${props => props.theme.neonShadow.pink};
    color: ${props => props.theme.primary};
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.text};
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1000;
  border: 1px solid ${props => props.theme.primary};
  box-shadow: ${props => props.theme.neonShadow.pink};
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  font-family: ${props => props.theme.font};

  ${GameButton}:hover & {
    opacity: 1;
  }
`;

const StatusIndicator = styled.div`
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.7rem;
  color: ${props => props.theme.secondary};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;

  &::before {
    content: '// ';
    color: ${props => props.theme.primary};
  }
`;

const GameProfileSelector = ({ currentGame, setCurrentGame, gameProfiles, setToast }) => {
  const [hoveredGame, setHoveredGame] = useState(null);
  const games = ['lol', 'valorant', 'tft', 'lor'];
  const availableGames = ['lol'];

  const handleGameChange = (game) => {
    if (availableGames.includes(game)) {
      setCurrentGame(game);
    } else {
      setToast({
        message: `${game.toUpperCase()} data unavailable. Awaiting API integration.`,
        type: 'info'
      });
    }
  };

  const handlePrevGame = () => {
    const currentIndex = games.indexOf(currentGame);
    if (currentIndex > 0) {
      handleGameChange(games[currentIndex - 1]);
    }
  };

  const handleNextGame = () => {
    const currentIndex = games.indexOf(currentGame);
    if (currentIndex < games.length - 1) {
      handleGameChange(games[currentIndex + 1]);
    }
  };

  const transitions = useTransition(currentGame, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-20px)' },
  });

  return (
    <SelectorContainer>
      <IconButton
        onClick={handlePrevGame}
        disabled={games.indexOf(currentGame) === 0}
        aria-label="Previous game"
      >
        <ChevronLeft size={24} />
      </IconButton>

      {transitions((style, item) => (
        <GameButtonContainer style={style}>
          {games.map(game => (
            <GameButton
              key={game}
              $active={game === item}
              $available={availableGames.includes(game)}
              onClick={() => handleGameChange(game)}
              onMouseEnter={() => setHoveredGame(game)}
              onMouseLeave={() => setHoveredGame(null)}
              aria-label={`Select ${game.toUpperCase()}`}
              aria-pressed={game === item}
              className={game === item ? 'glitch-text' : ''}
              data-text={game.toUpperCase()}
            >
              {game.toUpperCase()}
              {!availableGames.includes(game) && <UnavailableIcon size={14} />}
              {!availableGames.includes(game) && hoveredGame === game && (
                <Tooltip>
                  AWAITING API INTEGRATION
                </Tooltip>
              )}
            </GameButton>
          ))}
        </GameButtonContainer>
      ))}

      <IconButton
        onClick={handleNextGame}
        disabled={games.indexOf(currentGame) === games.length - 1}
        aria-label="Next game"
      >
        <ChevronRight size={24} />
      </IconButton>

      <AddProfileButton as={Link} to="/profile-management" aria-label="Add new game profile">
        <PlusCircle size={24} />
      </AddProfileButton>

      <StatusIndicator>
        SYSTEM STATUS: ONLINE
      </StatusIndicator>
    </SelectorContainer>
  );
};

export default GameProfileSelector;
