import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, LogIn, BarChart2, Brain, Users, Play, X, ChevronRight } from 'lucide-react';
import YouTube from 'react-youtube';
import api from '../utils/api';

const HomeContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('/images/hero-image.jpg');
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 800px;
  padding: 0 20px;
`;

const Title = styled(motion.h1)`
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: ${props => props.theme.primary};
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 30px;
  color: ${props => props.theme.text};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const Button = styled(motion(Link))`
  padding: 12px 24px;
  background-color: ${props => props.primary ? props.theme.primary : 'transparent'};
  color: ${props => props.primary ? props.theme.text : props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.primary ? props.theme.accent1 : props.theme.background};
  }
`;

const IntroVideoSection = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.theme.cardBackground};
`;

const VideoContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

const VideoThumbnail = styled.img`
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const FeaturesSection = styled.section`
  padding: 80px 20px;
  background-color: ${props => props.theme.background};
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
`;

const FeatureCard = styled(motion.div)`
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const FeatureIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;
  color: ${props => props.theme.primary};
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: ${props => props.theme.primary};
`;

const FeatureDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.textSecondary};
  margin: 0 0 15px 0;
`;

const Footer = styled.footer`
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.textSecondary};
  text-align: center;
  padding: 20px;
  font-size: 0.9rem;
`;

const UpdateBanner = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const UpdateMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UpdateLink = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;

  &:hover {
    color: ${props => props.theme.accent1};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const UpdateModal = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props => props.theme.cardBackground};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  z-index: 1001;
`;

const UpdateModalContent = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const UpdateModalClose = styled(CloseButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.textSecondary};
`;

function Home() {
  const [introVideoId, setIntroVideoId] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showUpdateBanner, setShowUpdateBanner] = useState(true);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    const fetchIntroVideo = async () => {
      try {
        const response = await api.get('/videos/intro');
        setIntroVideoId(response.data.youtubeId);
      } catch (error) {
        console.error('Error fetching intro video:', error);
      }
    };

    fetchIntroVideo();
  }, []);

  return (
    <HomeContainer>
      <AnimatePresence>
        {showUpdateBanner && (
          <UpdateBanner
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -100 }}
            transition={{ type: 'spring', stiffness: 500, damping: 30 }}
          >
            <UpdateMessage>
              🎉 We've made some exciting improvements!
              <UpdateLink onClick={() => setShowUpdateModal(true)}>
                Learn more <ChevronRight size={16} />
              </UpdateLink>
            </UpdateMessage>
            <CloseButton onClick={() => setShowUpdateBanner(false)}>
              <X size={20} />
            </CloseButton>
          </UpdateBanner>
        )}
      </AnimatePresence>

      <HeroSection>
        <HeroContent>
          <Title
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            LoL Performance Hub
          </Title>
          <Subtitle
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Elevate your League of Legends gameplay with AI-powered insights and personalized recommendations
          </Subtitle>
          <ButtonContainer>
            <Button
              to="/register"
              primary
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Get Started <ArrowRight size={20} />
            </Button>
            <Button
              to="/login"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              Login <LogIn size={20} />
            </Button>
          </ButtonContainer>
        </HeroContent>
      </HeroSection>

      {introVideoId && (
        <IntroVideoSection>
          <VideoContainer>
            {!isPlaying ? (
              <>
                <VideoThumbnail
                  src={`https://img.youtube.com/vi/${introVideoId}/maxresdefault.jpg`}
                  alt="Intro Video Thumbnail"
                  onClick={() => setIsPlaying(true)}
                />
                <PlayButton onClick={() => setIsPlaying(true)}>
                  <Play size={32} color={props => props.theme.primary} />
                </PlayButton>
              </>
            ) : (
              <YouTube
                videoId={introVideoId}
                opts={{
                  width: '100%',
                  height: '450',
                  playerVars: {
                    autoplay: 1,
                  },
                }}
              />
            )}
          </VideoContainer>
        </IntroVideoSection>
      )}

      <FeaturesSection>
        <FeatureGrid>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <FeatureIcon><BarChart2 size={48} /></FeatureIcon>
            <FeatureTitle>Advanced Analytics</FeatureTitle>
            <FeatureDescription>
              Dive deep into your performance stats with intuitive visualizations and trend analysis
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <FeatureIcon><Brain size={48} /></FeatureIcon>
            <FeatureTitle>AI-Powered Insights</FeatureTitle>
            <FeatureDescription>
              Receive personalized recommendations and strategies based on your unique playstyle
            </FeatureDescription>
          </FeatureCard>
          <FeatureCard
            whileHover={{ y: -10 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <FeatureIcon><Users size={48} /></FeatureIcon>
            <FeatureTitle>Community Insights</FeatureTitle>
            <FeatureDescription>
              Learn from top players and share strategies with a community of dedicated gamers
            </FeatureDescription>
          </FeatureCard>
        </FeatureGrid>
      </FeaturesSection>

      <Footer>
        © {new Date().getFullYear()} LoL Performance Hub. All rights reserved.
      </Footer>

      <AnimatePresence>
        {showUpdateModal && (
          <UpdateModal
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ type: 'spring', stiffness: 500, damping: 30 }}
          >
            <UpdateModalClose onClick={() => setShowUpdateModal(false)}>
              <X size={20} />
            </UpdateModalClose>
            <h2>Recent Updates</h2>
            <UpdateModalContent>
              <h3>Latest Improvements</h3>
              <ul>
                <li>Fixed logins for players previously unable to login after registration</li>
                <li>Added new themes</li>
                <li>Finally didn't blame my teammates for losing</li>
              </ul>
              <p>Thank you for being part of our community!</p>
              <p>To provide feedback, for now please send it all as comments on the videos. Working on new feedback input methods.</p>
            </UpdateModalContent>
          </UpdateModal>
        )}
      </AnimatePresence>
    </HomeContainer>
  );
}

export default Home;
