import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { RefreshCw, Coffee, HelpCircle, Terminal, Cpu, Shield, Zap } from 'lucide-react';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';
import GameProfileSelector from './GameProfileSelector';
import PerformanceTrends from './PerformanceTrends';
import Recommendations from './Recommendations';
import ResourceHub from './ResourceHub';
import FeedbackModal from './FeedbackModal';
import IntroModal from './IntroModal';
import WelcomeOverlay from './WelcomeOverlay';
import { useTheme } from '../ThemeContext';

// Styled Components
const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 60px auto 0;
  padding: ${props => props.theme.spacing.large};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    background: ${props => props.theme.gradients?.pink || props.theme.primary};
    box-shadow: ${props => props.theme.neonShadow?.pink || `0 0 10px ${props.theme.primary}`};
  }

  &::after {
    content: 'PERFORMANCE_MATRIX_V2.4';
    position: absolute;
    top: -20px;
    right: 20px;
    font-family: ${props => props.theme.font};
    font-size: 0.7rem;
    color: ${props => props.theme.secondary};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.medium};
  padding: 10px 20px;
  background: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid rgba(255, 42, 109, 0.2);
  box-shadow: 0 0 10px rgba(255, 42, 109, 0.1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${props => props.theme.gradients.blue};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.gradients.pink};
    opacity: 0.5;
  }
`;

const SystemStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: ${props => props.theme.font};
  font-size: 0.8rem;
  color: ${props => props.theme.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;

  & > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  svg {
    color: ${props => props.theme.success};
    filter: drop-shadow(0 0 2px ${props => props.theme.success});
  }
`;

const StatusIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.success};
  box-shadow: 0 0 5px ${props => props.theme.success};
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }
`;

const HelpButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: conic-gradient(
      from 0deg,
      transparent 0%,
      ${props => props.theme.secondary} 25%,
      transparent 25%
    );
    animation: rotate 4s linear infinite;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    color: ${props => props.theme.primary};
    box-shadow: ${props => props.theme.neonShadow.pink};

    &::before {
      opacity: 0.1;
    }
  }

  @keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing.large};
  margin: 20px 0;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  &::before {
    content: 'ANALYZING_DATA';
    position: absolute;
    top: -20px;
    left: 0;
    font-family: ${props => props.theme.font};
    color: ${props => props.theme.secondary};
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const UpdateButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background-color: transparent;
  color: ${props => props.theme.secondary};
  border: 1px solid ${props => props.theme.secondary};
  border-radius: ${props => props.theme.borderRadius};
  font-family: ${props => props.theme.font};
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  margin: 20px 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: conic-gradient(
      from 0deg,
      transparent 0%,
      ${props => props.theme.secondary} 25%,
      transparent 25%
    );
    animation: rotate 4s linear infinite;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover:not(:disabled) {
    color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    box-shadow: ${props => props.theme.neonShadow.pink};

    &::before {
      opacity: 0.1;
    }
  }

  &:disabled {
    border-color: ${props => props.theme.textSecondary};
    color: ${props => props.theme.textSecondary};
    cursor: not-allowed;
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover:not(:disabled) svg {
    transform: rotate(180deg);
  }
`;

const TerminalPrompt = styled.div`
  color: ${props => props.theme.secondary};
  font-family: ${props => props.theme.font};
  font-size: 0.8rem;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0.8;

  &::before {
    content: '>';
    color: ${props => props.theme.primary};
  }

  animation: blink 1s step-end infinite;

  @keyframes blink {
    50% { opacity: 0; }
  }
`;

const LastUpdated = styled.div`
  text-align: center;
  color: ${props => props.theme.secondary};
  font-size: 0.8rem;
  margin-top: ${props => props.theme.spacing.large};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

  &::before {
    content: '// ';
    color: ${props => props.theme.primary};
  }

  span {
    color: ${props => props.theme.error};
  }
`;

const BuyMeCoffeeButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  background: transparent;
  color: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.primary};
  text-decoration: none;
  border-radius: ${props => props.theme.borderRadius};
  font-family: ${props => props.theme.font};
  font-weight: 600;
  margin-top: ${props => props.theme.spacing.large};
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 42, 109, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    box-shadow: ${props => props.theme.neonShadow.pink};

    &::before {
      left: 100%;
    }
  }

  svg {
    filter: drop-shadow(0 0 2px ${props => props.theme.primary});
  }
`;

// Dashboard Component Implementation
function Dashboard() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [currentGame, setCurrentGame] = useState('lol');
  const [isProfilePrivate, setIsProfilePrivate] = useState(false);
  const [hasNoGameHistory, setHasNoGameHistory] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [introModalOpen, setIntroModalOpen] = useState(false);
  const [showWelcomeOverlay, setShowWelcomeOverlay] = useState(false);
  const [currentRecommendation, setCurrentRecommendation] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { currentTheme } = useTheme();
  const [systemStatus, setSystemStatus] = useState('INITIALIZING');

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setShowWelcomeOverlay(true);
    }
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsUpdating(true);
      setError(null);
      setIsProfilePrivate(false);
      setHasNoGameHistory(false);
      setSystemStatus('SCANNING');

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('ACCESS_DENIED: Authentication required');
      }

      // Fetch user data first
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserData(userResponse.data);
      setSystemStatus('PROCESSING_USER_DATA');

      const gameProfile = userResponse.data.gameProfiles[currentGame];
      if (!gameProfile || !gameProfile.profile || !gameProfile.region) {
        setError('ERROR: Game profile not configured');
        setPlayerData(null);
        return;
      }

      const [gameName, tagLine] = gameProfile.profile.split('#');

      try {
        setSystemStatus('ANALYZING_PERFORMANCE');
        const playerResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/player/${currentGame}/analyze/${encodeURIComponent(gameName)}/${encodeURIComponent(tagLine)}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (playerResponse.data.isPrivate) {
          setIsProfilePrivate(true);
          setPlayerData(null);
          setSystemStatus('ACCESS_RESTRICTED');
        } else if (playerResponse.data.noGameHistory) {
          setHasNoGameHistory(true);
          setPlayerData(null);
          setSystemStatus('NO_DATA_FOUND');
        } else {
          setPlayerData(playerResponse.data);
          setIsProfilePrivate(false);
          setHasNoGameHistory(false);
          setSystemStatus('ANALYSIS_COMPLETE');
        }

        setLastUpdated(new Date());
      } catch (playerError) {
        console.error('Error fetching player data:', playerError);
        setError(`ERROR_CODE_${playerError.response?.status || 'UNKNOWN'}: ${playerError.message}`);
        setPlayerData(null);
        setSystemStatus('ERROR');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.response?.data?.error || error.message);
      setPlayerData(null);
      setSystemStatus('ERROR');
    } finally {
      setIsUpdating(false);
      setLoading(false);
    }
  }, [currentGame]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleRecommendationFeedback = async (recommendation, isHelpful) => {
    setSystemStatus('PROCESSING_FEEDBACK');
    if (isHelpful) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/feedback/upvote`, {
          recommendation,
          game: currentGame
        });
        setSystemStatus('FEEDBACK_RECORDED');
      } catch (error) {
        console.error('Error sending upvote:', error);
        setSystemStatus('FEEDBACK_ERROR');
      }
    } else {
      setCurrentRecommendation(recommendation);
      setFeedbackModalOpen(true);
    }
  };

  const handleFeedbackSubmit = async (feedback) => {
    setSystemStatus('PROCESSING_FEEDBACK');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/feedback/downvote`, {
        recommendation: currentRecommendation,
        feedback,
        game: currentGame
      });
      setFeedbackModalOpen(false);
      setSystemStatus('FEEDBACK_RECORDED');
    } catch (error) {
      console.error('Error sending feedback:', error);
      setSystemStatus('FEEDBACK_ERROR');
    }
  };

  const handleWelcomeOverlayClose = () => {
    setShowWelcomeOverlay(false);
    localStorage.setItem('hasSeenWelcome', 'true');
  };

  return (
    <DashboardContainer>
      <TopBar>
        <SystemStatus>
          <div>
            <Cpu size={14} />
            <StatusIndicator />
            SYSTEM_{systemStatus}
          </div>
          <div>
            <Terminal size={14} />
            {isUpdating ? 'SCANNING...' : 'READY'}
          </div>
          <div>
            <Zap size={14} />
            {playerData ? 'CONNECTED' : 'STANDBY'}
          </div>
        </SystemStatus>
        <HelpButton onClick={() => setIntroModalOpen(true)}>
          <HelpCircle size={20} />
        </HelpButton>
      </TopBar>

      <GameProfileSelector
        currentGame={currentGame}
        setCurrentGame={setCurrentGame}
        gameProfiles={userData?.gameProfiles}
      />

      <TerminalPrompt>
        {isUpdating ? 'SCANNING_IN_PROGRESS...' : 'READY_FOR_SCAN >'}
      </TerminalPrompt>

      <UpdateButton onClick={fetchData} disabled={isUpdating}>
        <RefreshCw size={16} />
        {isUpdating ? 'SCANNING...' : 'UPDATE_ANALYSIS'}
      </UpdateButton>

      {error ? (
        <Alert
          title="ERROR_DETECTED"
          description={error}
          action={{
            label: 'CONFIGURE_PROFILE',
            onClick: () => navigate('/profile-management')
          }}
        />
      ) : (
        <Grid>
          <PerformanceTrends
            playerData={playerData}
            currentGame={currentGame}
            isProfilePrivate={isProfilePrivate}
            hasNoGameHistory={hasNoGameHistory}
            loading={loading}
          />
          <Recommendations
            playerData={playerData}
            currentGame={currentGame}
            isProfilePrivate={isProfilePrivate}
            hasNoGameHistory={hasNoGameHistory}
            onFeedback={handleRecommendationFeedback}
            setCurrentRecommendation={setCurrentRecommendation}
            setFeedbackModalOpen={setFeedbackModalOpen}
            loading={loading}
          />
        </Grid>
      )}

      <ResourceHub currentGame={currentGame} userData={userData} />

      <FeedbackModal
        isOpen={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />

      <IntroModal
        isOpen={introModalOpen}
        onClose={() => setIntroModalOpen(false)}
      />

      <WelcomeOverlay
        isOpen={showWelcomeOverlay}
        onClose={handleWelcomeOverlayClose}
        onWatchIntro={() => {
          setShowWelcomeOverlay(false);
          setIntroModalOpen(true);
        }}
      />

      <LastUpdated>
        LAST_UPDATE: {lastUpdated ? lastUpdated.toLocaleString() : 'NEVER'}
        {lastUpdated && new Date() - lastUpdated > 24 * 60 * 60 * 1000 && (
          <span> // DATA_OUTDATED: RESCAN_RECOMMENDED</span>
        )}
      </LastUpdated>

      <BuyMeCoffeeButton
        href="https://ko-fi.com/miodragmtasic"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Coffee /> SUPPORT_OPERATIONS
      </BuyMeCoffeeButton>
    </DashboardContainer>
  );
}

export default Dashboard;
