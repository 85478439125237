// frontend/src/ThemeContext.js
import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { themes, validateTheme } from './theme';

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme && themes[savedTheme] ? savedTheme : 'midnightBlue';
  });

  const [currentTheme, setCurrentTheme] = useState(() => validateTheme(themes[themeName]));

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme && themes[savedTheme]) {
      setThemeName(savedTheme);
      setCurrentTheme(validateTheme(themes[savedTheme]));
    }
  }, []);

  const setTheme = useCallback((name) => {
    if (themes[name]) {
      setThemeName(name);
      const newTheme = validateTheme(themes[name]);
      setCurrentTheme(newTheme);
      localStorage.setItem('theme', name);
    } else {
      console.error(`Theme "${name}" not found`);
    }
  }, []);

  const toggleDarkMode = useCallback(() => {
    const currentMode = currentTheme.mode;
    const newMode = currentMode === 'light' ? 'dark' : 'light';
    const newThemeName = Object.keys(themes).find(
      key => themes[key].mode === newMode && themes[key].name.includes(currentTheme.name.replace('Light', '').replace('Dark', ''))
    );
    if (newThemeName) {
      setTheme(newThemeName);
    }
  }, [currentTheme.mode, currentTheme.name, setTheme]);

  const getOppositeTheme = useCallback(() => {
    const currentMode = currentTheme.mode;
    const oppositeMode = currentMode === 'light' ? 'dark' : 'light';
    return Object.values(themes).find(theme => theme.mode === oppositeMode);
  }, [currentTheme.mode]);

  const value = {
    themeName,
    setTheme,
    toggleDarkMode,
    currentTheme,
    getOppositeTheme,
  };

  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={currentTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
