// components/ProfileManagement.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Plus, Trash2, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Alert from './Alert';
import LoadingSpinner from './LoadingSpinner';
import Toast from './Toast';

const ProfileManagementContainer = styled.div`
  max-width: 800px;
  margin: 60px auto 0;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 30px;
  font-size: 2rem;
`;

const ProfileList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
`;

const ProfileItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: ${props => props.theme.background};
  border-radius: 8px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const GameName = styled.span`
  font-weight: bold;
  color: ${props => props.theme.text};
  font-size: 1.1rem;
`;

const ProfileInfo = styled.div`
  color: ${props => props.theme.textSecondary};
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ProfileDetail = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
`;

const Button = styled.button`
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.3s ease;
  font-size: 0.9rem;

  &:hover {
    background-color: ${props => props.theme.accent1};
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: ${props => props.theme.secondary};
    cursor: not-allowed;
  }
`;

const DeleteButton = styled(Button)`
  background-color: ${props => props.theme.error};
  padding: 6px 12px;

  &:hover {
    background-color: ${props => props.theme.accent3};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: ${props => props.theme.background};
  padding: 20px;
  border-radius: 8px;
`;

const FormTitle = styled.h2`
  color: ${props => props.theme.primary};
  margin-bottom: 15px;
  font-size: 1.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 5px;
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }

  &:invalid {
    border-color: ${props => props.$touched ? props.theme.error : props.theme.secondary};
  }
`;

const Select = styled.select`
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  transition: border-color 0.3s ease;

  &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const gameLabels = {
  lol: 'League of Legends',
  valorant: 'Valorant',
  tft: 'Teamfight Tactics',
  lor: 'Legends of Runeterra'
};

const regions = {
  lol: [
    { value: 'na', label: 'North America' },
    { value: 'euw', label: 'Europe West' },
    { value: 'eune', label: 'Europe Nordic & East' },
    { value: 'kr', label: 'Korea' },
    { value: 'br', label: 'Brazil' },
    { value: 'jp', label: 'Japan' },
    { value: 'lan', label: 'Latin America North' },
    { value: 'las', label: 'Latin America South' },
    { value: 'oce', label: 'Oceania' },
    { value: 'tr', label: 'Turkey' },
    { value: 'ru', label: 'Russia' }
  ],
  valorant: [
    { value: 'na', label: 'North America' },
    { value: 'eu', label: 'Europe' },
    { value: 'kr', label: 'Korea' },
    { value: 'ap', label: 'Asia Pacific' }
  ],
  tft: [
    { value: 'na', label: 'North America' },
    { value: 'euw', label: 'Europe West' },
    { value: 'eune', label: 'Europe Nordic & East' },
    { value: 'kr', label: 'Korea' }
  ],
  lor: [
    { value: 'americas', label: 'Americas' },
    { value: 'europe', label: 'Europe' },
    { value: 'asia', label: 'Asia' }
  ]
};

function ProfileManagement() {
  const [profiles, setProfiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newProfile, setNewProfile] = useState({
    game: '',
    inGameName: '',
    tagLine: '',
    region: ''
  });
  const [toast, setToast] = useState(null);
  const [touchedFields, setTouchedFields] = useState({
    inGameName: false,
    tagLine: false
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfiles();
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/profile`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfiles(response.data.gameProfiles);
    } catch (error) {
      console.error('Error fetching profiles:', error);
      setError(error.response?.data?.error || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddProfile = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      const profile = `${newProfile.inGameName}#${newProfile.tagLine}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update-game-profile`,
        {
          game: newProfile.game,
          profile: profile,
          region: newProfile.region
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProfiles(response.data.gameProfiles);
      setNewProfile({ game: '', inGameName: '', tagLine: '', region: '' });
      setTouchedFields({ inGameName: false, tagLine: false });
      setToast({ message: 'Profile added successfully', type: 'success' });
    } catch (error) {
      console.error('Error adding profile:', error);
      setError(error.response?.data?.error || error.message);
      setToast({ message: 'Failed to add profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProfile = async (game) => {
    if (!window.confirm(`Are you sure you want to delete your ${gameLabels[game]} profile?`)) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found. Please log in again.');
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update-game-profile`,
        { game, profile: null, region: null },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setProfiles(prevProfiles => {
        const updatedProfiles = { ...prevProfiles };
        delete updatedProfiles[game];
        return updatedProfiles;
      });
      setToast({ message: 'Profile deleted successfully', type: 'success' });
    } catch (error) {
      console.error('Error deleting profile:', error);
      setError(error.response?.data?.error || error.message);
      setToast({ message: 'Failed to delete profile', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const isValidInput = (input) => /^[a-zA-Z0-9]+$/.test(input);

  const isFormValid = () => {
    return (
      newProfile.game &&
      isValidInput(newProfile.inGameName) &&
      isValidInput(newProfile.tagLine) &&
      newProfile.region
    );
  };

  const handleInputChange = (e, field) => {
    setNewProfile({ ...newProfile, [field]: e.target.value });
    setTouchedFields({ ...touchedFields, [field]: true });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Alert title="Error" description={error} />;

  return (
    <ProfileManagementContainer>
      <Title>Game Profiles</Title>
      <ProfileList>
        {Object.entries(profiles).map(([game, profile]) => (
          <ProfileItem key={game}>
            <div>
              <GameName>{gameLabels[game]}</GameName>
              <ProfileInfo>
                <ProfileDetail>Profile: {profile.profile}</ProfileDetail>
                <ProfileDetail>Region: {regions[game].find(r => r.value === profile.region)?.label}</ProfileDetail>
              </ProfileInfo>
            </div>
            <DeleteButton onClick={() => handleDeleteProfile(game)}>
              <Trash2 size={16} /> Delete
            </DeleteButton>
          </ProfileItem>
        ))}
      </ProfileList>
      <Form onSubmit={handleAddProfile}>
        <FormTitle>Add New Profile</FormTitle>
        <InputContainer>
          <Label htmlFor="game">Game</Label>
          <Select
            id="game"
            value={newProfile.game}
            onChange={(e) => setNewProfile({
              ...newProfile,
              game: e.target.value,
              region: ''  // Reset region when game changes
            })}
            required
          >
            <option value="">Select Game</option>
            {Object.entries(gameLabels).map(([value, label]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </Select>
        </InputContainer>

        {newProfile.game && (
          <>
            <InputContainer>
              <Label htmlFor="region">Region</Label>
              <Select
                id="region"
                value={newProfile.region}
                onChange={(e) => handleInputChange(e, 'region')}
                required
              >
                <option value="">Select Region</option>
                {regions[newProfile.game].map(region => (
                  <option key={region.value} value={region.value}>
                    {region.label}
                  </option>
                ))}
              </Select>
            </InputContainer>

            <InputContainer>
              <Label htmlFor="inGameName">{gameLabels[newProfile.game]} ID</Label>
              <Input
                id="inGameName"
                type="text"
                value={newProfile.inGameName}
                onChange={(e) => handleInputChange(e, 'inGameName')}
                pattern="[a-zA-Z0-9]+"
                required
                $touched={touchedFields.inGameName}
              />
            </InputContainer>

            <InputContainer>
              <Label htmlFor="tagline">Tagline</Label>
              <Input
                id="tagline"
                type="text"
                value={newProfile.tagLine}
                onChange={(e) => handleInputChange(e, 'tagLine')}
                pattern="[a-zA-Z0-9]+"
                required
                $touched={touchedFields.tagLine}
              />
            </InputContainer>
          </>
        )}

        <ButtonContainer>
          <Button type="button" onClick={() => navigate('/dashboard')}>
            <ChevronLeft size={16} /> Back to Dashboard
          </Button>
          <Button type="submit" disabled={!isFormValid()}>
            <Plus size={16} /> Add Profile
          </Button>
        </ButtonContainer>
      </Form>
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}
    </ProfileManagementContainer>
  );
}

export default ProfileManagement;
