import { createGlobalStyle, keyframes } from 'styled-components';

const scanline = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh);
  }
`;

const glitch = keyframes`
  0% {
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  4% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  10% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  16% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.font || 'Roboto, sans-serif'};
    background: ${props => props.theme.background};
    color: ${props => props.theme.text};
    transition: all 0.3s ease;

    ${props => props.theme.name === 'Cyberpunk' && `
      background-image: ${props.theme.gridBackground};
      background-size: ${props.theme.gridSize} ${props.theme.gridSize};

      &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle at center, transparent 0%, ${props.theme.background} 100%);
        pointer-events: none;
        z-index: 1;
      }

      &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.1) 51%, transparent 100%);
        background-size: 100% 4px;
        animation: ${scanline} 4s linear infinite;
        pointer-events: none;
        opacity: 0.1;
        z-index: 2;
      }
    `}
  }

  * {
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    z-index: 3;
  }

  main {
    flex: 1;
    padding-top: 60px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.primary};
    ${props => props.theme.name === 'Cyberpunk' && `
      text-transform: uppercase;
      letter-spacing: 2px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background: ${props.theme.gradients.pink};
        box-shadow: ${props.theme.neonShadow.pink};
      }
    `}
  }

  button {
    font-family: inherit;
    transition: all 0.3s ease;

    ${props => props.theme.name === 'Cyberpunk' && `
      text-transform: uppercase;
      letter-spacing: 1px;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          120deg,
          transparent,
          rgba(255, 255, 255, 0.2),
          transparent
        );
        transition: 0.5s;
      }

      &:hover::before {
        left: 100%;
      }

      &:hover {
        box-shadow: ${props.theme.neonShadow.pink};
      }
    `}
  }

  input, textarea, select {
    font-family: inherit;
    background-color: ${props => props.theme.cardBackground};
    color: ${props => props.theme.text};
    border: 1px solid ${props => props.theme.secondary};
    border-radius: ${props => props.theme.borderRadius};
    padding: 8px 12px;
    transition: all 0.3s ease;

    ${props => props.theme.name === 'Cyberpunk' && `
      border-color: ${props.theme.secondary};

      &:focus {
        border-color: ${props.theme.primary};
        box-shadow: ${props.theme.neonShadow.blue};
        outline: none;
      }
    `}
  }

  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.theme.background};
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.primary};
    border-radius: 4px;

    ${props => props.theme.name === 'Cyberpunk' && `
      box-shadow: ${props.theme.neonShadow.pink};
    `}
  }

  /* Accessibility Styles */
  :focus {
    outline: 2px solid ${props => props.theme.accent1};
    outline-offset: 2px;
  }

  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  /* Cyberpunk-specific text glitch effect class */
  ${props => props.theme.name === 'Cyberpunk' && `
    .glitch-text {
      position: relative;
      animation: ${glitch} 2s infinite;

      &::before,
      &::after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::before {
        left: 2px;
        text-shadow: -2px 0 ${props.theme.accent1};
        clip: rect(44px, 450px, 56px, 0);
        animation: ${glitch} 5s infinite linear alternate-reverse;
      }

      &::after {
        left: -2px;
        text-shadow: -2px 0 ${props.theme.accent2};
        clip: rect(44px, 450px, 56px, 0);
        animation: ${glitch} 5s infinite linear alternate-reverse;
      }
    }
  `}
`;

export default GlobalStyles;
