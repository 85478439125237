import React from 'react';
import styled from 'styled-components';
import { AlertTriangle } from 'lucide-react';

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.colors?.error || '#ff1f1f'};
`;

const ErrorMessage = styled.h3`
  color: ${props => props.theme.colors?.error || '#ff1f1f'};
  margin: 10px 0;
  font-family: ${props => props.theme.font || "'JetBrains Mono', monospace"};
  text-transform: uppercase;
`;

const ErrorDetails = styled.pre`
  color: ${props => props.theme.textSecondary};
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 10px;
  background-color: ${props => props.theme.background};
  border-radius: 4px;
  max-width: 100%;
  overflow-x: auto;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.error('Chart error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <AlertTriangle size={48} />
          <ErrorMessage>SYSTEM_ERROR: Unable to display chart</ErrorMessage>
          {process.env.NODE_ENV === 'development' && this.state.error && (
            <ErrorDetails>
              {this.state.error.toString()}
              {this.state.errorInfo && this.state.errorInfo.componentStack}
            </ErrorDetails>
          )}
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
