import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { UserPlus, ArrowLeft, Plus, Minus } from 'lucide-react';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const Form = styled(motion.form)`
  width: 100%;
  max-width: 600px;
  background-color: ${props => props.theme.cardBackground};
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  color: ${props => props.theme.primary};
  text-align: center;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
`;

const Button = styled(motion.button)`
  width: 100%;
  margin: 20px 0 10px;
  padding: 10px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.text};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &:hover {
    background-color: ${props => props.theme.accent1};
  }

  &:disabled {
    background-color: ${props => props.theme.secondary};
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled(motion.div)`
  color: ${props => props.theme.error};
  margin-bottom: 10px;
  text-align: center;
`;

const BackButton = styled(Link)`
  margin-top: 20px;
  color: ${props => props.theme.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const GameProfilesSection = styled.div`
  margin: 20px 0;
`;

const GameProfileForm = styled.div`
  background-color: ${props => props.theme.background};
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
`;

const GameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const GameTitle = styled.h3`
  color: ${props => props.theme.primary};
  margin: 0;
  font-size: 1.1rem;
`;

const ProfileInputs = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 4px;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  margin: 10px 0;

  &:focus {
    border-color: ${props => props.theme.primary};
    outline: none;
  }
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

function Register() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    gameProfiles: {
      lol: { enabled: true, profile: '', region: '' },
      valorant: { enabled: false, profile: '', region: '' },
      tft: { enabled: false, profile: '', region: '' },
      lor: { enabled: false, profile: '', region: '' }
    }
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const regions = {
    lol: [
      { value: 'na', label: 'North America' },
      { value: 'euw', label: 'Europe West' },
      { value: 'eune', label: 'Europe Nordic & East' },
      { value: 'kr', label: 'Korea' },
      { value: 'br', label: 'Brazil' },
      { value: 'jp', label: 'Japan' },
      { value: 'lan', label: 'Latin America North' },
      { value: 'las', label: 'Latin America South' },
      { value: 'oce', label: 'Oceania' },
      { value: 'tr', label: 'Turkey' },
      { value: 'ru', label: 'Russia' }
    ],
    valorant: [
      { value: 'na', label: 'North America' },
      { value: 'eu', label: 'Europe' },
      { value: 'kr', label: 'Korea' },
      { value: 'ap', label: 'Asia Pacific' }
    ],
    tft: [
      { value: 'na', label: 'North America' },
      { value: 'euw', label: 'Europe West' },
      { value: 'eune', label: 'Europe Nordic & East' },
      { value: 'kr', label: 'Korea' }
    ],
    lor: [
      { value: 'americas', label: 'Americas' },
      { value: 'europe', label: 'Europe' },
      { value: 'asia', label: 'Asia' }
    ]
  };

  const gameLabels = {
    lol: 'League of Legends',
    valorant: 'Valorant',
    tft: 'Teamfight Tactics',
    lor: 'Legends of Runeterra'
  };

  const toggleGame = (game) => {
    setFormData(prev => ({
      ...prev,
      gameProfiles: {
        ...prev.gameProfiles,
        [game]: {
          ...prev.gameProfiles[game],
          enabled: !prev.gameProfiles[game].enabled,
          profile: '',
          region: ''
        }
      }
    }));
  };

  const handleGameProfileChange = (game, field, value) => {
    setFormData(prev => ({
      ...prev,
      gameProfiles: {
        ...prev.gameProfiles,
        [game]: {
          ...prev.gameProfiles[game],
          [field]: value
        }
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Format game profiles for submission
      const formattedGameProfiles = {};
      Object.entries(formData.gameProfiles).forEach(([game, data]) => {
        if (data.enabled && data.profile && data.region) {
          formattedGameProfiles[game] = {
            profile: data.profile,
            region: data.region
          };
        }
      });

      // Validate at least one game profile is enabled and complete
      if (Object.keys(formattedGameProfiles).length === 0) {
        setError('Please complete at least one game profile');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
        gameProfiles: formattedGameProfiles
      });

      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration error:', error.response?.data || error.message);
      setError(error.response?.data?.error || 'An error occurred during registration');
    }
  };

  return (
    <RegisterContainer>
      <Form
        onSubmit={handleSubmit}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Title>Register</Title>
        {error && (
          <ErrorMessage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {error}
          </ErrorMessage>
        )}

        <Input
          type="text"
          placeholder="Username"
          value={formData.username}
          onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
          required
        />
        <Input
          type="email"
          placeholder="Email"
          value={formData.email}
          onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={formData.password}
          onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
          required
        />

        <GameProfilesSection>
          {Object.entries(formData.gameProfiles).map(([game, data]) => (
            <GameProfileForm key={game}>
              <GameHeader>
                <GameTitle>{gameLabels[game]}</GameTitle>
                <ToggleButton type="button" onClick={() => toggleGame(game)}>
                  {data.enabled ? <Minus size={18} /> : <Plus size={18} />}
                  {data.enabled ? 'Remove' : 'Add'}
                </ToggleButton>
              </GameHeader>

              {data.enabled && (
                <>
                  <Input
                    type="text"
                    placeholder={`${gameLabels[game]} ID`}
                    value={data.profile}
                    onChange={(e) => handleGameProfileChange(game, 'profile', e.target.value)}
                    required
                  />
                  <Select
                    value={data.region}
                    onChange={(e) => handleGameProfileChange(game, 'region', e.target.value)}
                    required
                  >
                    <option value="">Select Region</option>
                    {regions[game].map(region => (
                      <option key={region.value} value={region.value}>
                        {region.label}
                      </option>
                    ))}
                  </Select>
                </>
              )}
            </GameProfileForm>
          ))}
        </GameProfilesSection>

        <Button
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <UserPlus size={18} />
          Register
        </Button>
      </Form>

      <BackButton to="/">
        <ArrowLeft size={18} />
        Back to Home
      </BackButton>
    </RegisterContainer>
  );
}

export default Register;
