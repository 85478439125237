// frontend/src/theme.js

// Base color constants
const CYBERPUNK_COLORS = {
  pink: '#ff2a6d',
  blue: '#05d9e8',
  purple: '#7700ff',
  success: '#01ffc3',
  error: '#ff1f1f',
  background: '#0a0a2c',
  cardBackground: '#0f0f3c',
  cardBackgroundHover: '#1a1a4c',
  text: '#ffffff',
  textLight: '#d1d1e1',
  textSecondary: '#7e7e9a',
  textDark: '#0a0a2c',
  deepBlue: '#005678',
};

const baseTheme = {
  transition: 'all 0.3s ease',
  borderRadius: '8px',
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
};

export const themes = {
  cyberpunk: {
    ...baseTheme,
    name: 'Cyberpunk',
    mode: 'dark',
    font: "'JetBrains Mono', monospace",

    // Base colors
    background: CYBERPUNK_COLORS.background,
    cardBackground: CYBERPUNK_COLORS.cardBackground,
    cardBackgroundHover: CYBERPUNK_COLORS.cardBackgroundHover,

    // Primary colors
    primary: CYBERPUNK_COLORS.pink,
    secondary: CYBERPUNK_COLORS.blue,
    accent1: CYBERPUNK_COLORS.purple,
    accent2: CYBERPUNK_COLORS.deepBlue,
    accent3: CYBERPUNK_COLORS.error,

    // Text colors
    text: CYBERPUNK_COLORS.text,
    textLight: CYBERPUNK_COLORS.textLight,
    textSecondary: CYBERPUNK_COLORS.textSecondary,
    textDark: CYBERPUNK_COLORS.textDark,

    // Status colors
    error: CYBERPUNK_COLORS.error,
    success: CYBERPUNK_COLORS.success,

    // Direct color access
    colors: CYBERPUNK_COLORS,

    // Special effects
    boxShadow: '0 0 10px rgba(255, 42, 109, 0.2)',
    neonShadow: {
      pink: `0 0 5px ${CYBERPUNK_COLORS.pink}, 0 0 10px ${CYBERPUNK_COLORS.pink}, 0 0 20px ${CYBERPUNK_COLORS.pink}`,
      blue: `0 0 5px ${CYBERPUNK_COLORS.blue}, 0 0 10px ${CYBERPUNK_COLORS.blue}, 0 0 20px ${CYBERPUNK_COLORS.blue}`,
      purple: `0 0 5px ${CYBERPUNK_COLORS.purple}, 0 0 10px ${CYBERPUNK_COLORS.purple}, 0 0 20px ${CYBERPUNK_COLORS.purple}`
    },

    // Gradients
    gradients: {
      pink: `linear-gradient(45deg, ${CYBERPUNK_COLORS.pink} 0%, ${CYBERPUNK_COLORS.error} 100%)`,
      blue: `linear-gradient(45deg, ${CYBERPUNK_COLORS.blue} 0%, ${CYBERPUNK_COLORS.deepBlue} 100%)`,
      purple: `linear-gradient(45deg, ${CYBERPUNK_COLORS.purple} 0%, ${CYBERPUNK_COLORS.pink} 100%)`,
    },

    // Grid background
    gridBackground: `
      linear-gradient(#1a1a4f 1px, transparent 1px),
      linear-gradient(90deg, #1a1a4f 1px, transparent 1px)
    `,
    gridSize: '30px',

    // Chart colors
    chartColors: [
      CYBERPUNK_COLORS.pink,
      CYBERPUNK_COLORS.blue,
      CYBERPUNK_COLORS.purple,
      CYBERPUNK_COLORS.success,
      CYBERPUNK_COLORS.error
    ],

    // Component-specific styles
    recommendationBorder: `2px solid ${CYBERPUNK_COLORS.pink}`,
    recommendationBackground: `rgba(255, 42, 109, 0.1)`,

    // Animations
    animations: {
      glow: 'glow 1.5s ease-in-out infinite alternate',
      flicker: 'flicker 0.3s ease-in-out infinite',
      scan: 'scan 2s linear infinite',
    },
  },

  // Keep this as fallback, but the app will primarily use cyberpunk theme
  midnightBlue: {
    ...baseTheme,
    name: 'Midnight Blue',
    mode: 'dark',
    background: '#0A1929',
    cardBackground: '#132F4C',
    cardBackgroundHover: '#1E3A5F',
    primary: '#5090D3',
    secondary: '#B2BAC2',
    accent1: '#FFD700',
    accent2: '#4DD0E1',
    accent3: '#9C27B0',
    text: '#FFFFFF',
    textLight: '#F0F0F0',
    textSecondary: '#D0D7DE',
    textDark: '#0A1929',
    error: '#FF6B6B',
    success: '#4CAF50',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    chartColors: ['#FFD700', '#4DD0E1', '#9C27B0', '#FF6B6B', '#4CAF50'],
    recommendationBorder: '2px solid #5090D3',
    recommendationBackground: 'rgba(80, 144, 211, 0.1)',
    colors: {
      blue: '#5090D3',
      pink: '#FF6B6B',
      purple: '#9C27B0'
    },
    gradients: {
      pink: 'linear-gradient(45deg, #FF6B6B 0%, #FF8E53 100%)',
      blue: 'linear-gradient(45deg, #5090D3 0%, #132F4C 100%)',
      purple: 'linear-gradient(45deg, #9C27B0 0%, #FF6B6B 100%)'
    },
    neonShadow: {
      pink: '0 0 5px #FF6B6B',
      blue: '0 0 5px #5090D3',
      purple: '0 0 5px #9C27B0'
    }
  }
};

export const validateTheme = (theme) => {
  const requiredProperties = [
    'name', 'mode', 'background', 'cardBackground', 'cardBackgroundHover',
    'primary', 'secondary', 'accent1', 'accent2', 'accent3',
    'text', 'textLight', 'textSecondary', 'textDark',
    'error', 'success', 'boxShadow', 'chartColors',
    'recommendationBorder', 'recommendationBackground',
    'colors', 'gradients', 'neonShadow'
  ];

  const missingProperties = requiredProperties.filter(prop => !(prop in theme));

  if (missingProperties.length > 0) {
    console.error(`Theme is missing required properties: ${missingProperties.join(', ')}`);
    return { ...themes.cyberpunk, ...theme };
  }

  return theme;
};

export default themes.cyberpunk; // Set cyberpunk as default theme
