import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Award, Brain, ThumbsUp, ThumbsDown, ChevronDown, ChevronUp, AlertTriangle, Cpu } from 'lucide-react';
import { animated, useSpring } from 'react-spring';
import { useAnimatedMount } from '../hooks/useAnimations';
import LoadingSpinner from './LoadingSpinner';

const scanline = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
`;

const glitch = keyframes`
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
`;

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const AnimatedCard = animated(styled.section`
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing.medium};
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(119, 0, 255, 0.2);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: ${props => props.theme.gradients.purple};
    box-shadow: ${props => props.theme.neonShadow.purple};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      transparent 50%,
      rgba(119, 0, 255, 0.05) 50%
    );
    background-size: 100% 4px;
    animation: ${scanline} 4s linear infinite;
    pointer-events: none;
  }
`);

const CardTitle = styled.h2`
  color: ${props => props.theme.accent1};
  border-bottom: 2px solid ${props => props.theme.accent1};
  padding-bottom: ${props => props.theme.spacing.small};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  font-size: 1.2rem;
  margin-bottom: ${props => props.theme.spacing.medium};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

  svg {
    filter: drop-shadow(0 0 2px ${props => props.theme.accent1});
  }

  &::after {
    content: 'AI_MODULE_V2.4';
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 0.7rem;
    color: ${props => props.theme.accent1};
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  text-align: center;
  gap: 20px;
`;

const MessageIcon = styled.div`
  font-size: 3rem;
  color: ${props => props.theme.accent1};
  animation: ${pulse} 2s infinite;

  svg {
    filter: drop-shadow(0 0 5px ${props => props.theme.accent1});
  }
`;

const MessageText = styled.p`
  font-size: 1.2rem;
  color: ${props => props.theme.text};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const RecommendationList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  position: relative;

  &::before {
    content: 'PROCESSING_RECOMMENDATIONS';
    position: absolute;
    top: -20px;
    left: 0;
    font-family: ${props => props.theme.font};
    font-size: 0.7rem;
    color: ${props => props.theme.accent1};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const RecommendationCard = styled(animated.li)`
  margin-bottom: ${props => props.theme.spacing.medium};
  padding: ${props => props.theme.spacing.medium};
  background-color: rgba(119, 0, 255, 0.1);
  border-radius: ${props => props.theme.borderRadius};
  border: 1px solid ${props => props.theme.accent1};
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${props => props.theme.neonShadow.purple};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background: ${props => props.$color || props.theme.accent1};
    box-shadow: 0 0 10px ${props => props.$color || props.theme.accent1};
  }
`;

const RecommendationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const RecommendationTitle = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  color: ${props => props.theme.text};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const ProcessingIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8rem;
  color: ${props => props.theme.accent1};
  font-family: ${props => props.theme.font};

  svg {
    animation: ${pulse} 2s infinite;
  }
`;

const RecommendationContent = styled.div`
  color: ${props => props.theme.text};
  font-size: 0.9rem;
  margin-bottom: 12px;
  line-height: 1.5;
  font-family: ${props => props.theme.font};
  position: relative;
  padding-left: 15px;

  &::before {
    content: '>';
    position: absolute;
    left: 0;
    color: ${props => props.theme.accent1};
  }
`;

const Stats = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 12px 0;
  padding: 12px 0;
  border-top: 1px solid rgba(119, 0, 255, 0.2);
  border-bottom: 1px solid rgba(119, 0, 255, 0.2);
  font-family: ${props => props.theme.font};
`;

const Stat = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  color: ${props => props.theme.textSecondary};
  text-transform: uppercase;
  letter-spacing: 1px;

  strong {
    color: ${props => props.theme.accent1};
    font-family: 'JetBrains Mono', monospace;
    text-shadow: 0 0 5px ${props => props.theme.accent1};
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
`;

const Button = styled.button`
  background: none;
  border: 1px solid ${props => props.theme.accent1};
  cursor: pointer;
  color: ${props => props.theme.text};
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-size: 0.9rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(119, 0, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover:not(:disabled) {
    background-color: rgba(119, 0, 255, 0.1);
    box-shadow: ${props => props.theme.neonShadow.purple};

    &::before {
      left: 100%;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: ${props => props.theme.textSecondary};
  }

  svg {
    filter: drop-shadow(0 0 2px ${props => props.theme.accent1});
  }
`;

const ExpandButton = styled(Button)`
  margin-left: auto;
`;

const DetailedAnalysis = styled(animated.div)`
  margin-top: 12px;
  padding: 12px;
  border: 1px solid rgba(119, 0, 255, 0.2);
  border-radius: 4px;
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  line-height: 1.6;
  font-family: ${props => props.theme.font};
  background: rgba(119, 0, 255, 0.05);
  position: relative;

  &::before {
    content: 'DETAILED_ANALYSIS';
    position: absolute;
    top: -8px;
    left: 10px;
    background: ${props => props.theme.cardBackground};
    padding: 0 5px;
    font-size: 0.7rem;
    color: ${props => props.theme.accent1};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const FeedbackMessage = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.accent2};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 5px;

  &::before {
    content: '//';
    color: ${props => props.theme.accent1};
  }
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 10, 44, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  z-index: 10;
  backdrop-filter: blur(3px);
`;

const LoadingText = styled.div`
  color: ${props => props.theme.accent1};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  animation: ${glitch} 1s infinite;
`;

function Recommendations({
  playerData,
  currentGame,
  isProfilePrivate,
  hasNoGameHistory,
  onFeedback,
  setCurrentRecommendation,
  setFeedbackModalOpen,
  loading
}) {
  const [expandedItems, setExpandedItems] = useState({});
  const [feedbackStates, setFeedbackStates] = useState({});
  const animationProps = useAnimatedMount();

  const recommendations = playerData?.recommendations || [];

  const handleFeedback = (index, isHelpful) => {
    if (isHelpful) {
      onFeedback(recommendations[index], true);
      setFeedbackStates(prev => ({
        ...prev,
        [index]: 'helpful'
      }));
    } else {
      setCurrentRecommendation(recommendations[index].content);
      setFeedbackModalOpen(true);
    }
  };

  const toggleExpand = (index) => {
    setExpandedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const cardSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { tension: 280, friction: 20 }
  });

  if (loading) {
    return (
      <AnimatedCard style={animationProps}>
        <CardTitle><Brain /> AI_ANALYSIS</CardTitle>
        <LoadingOverlay>
          <Cpu size={32} />
          <LoadingText>PROCESSING_ANALYSIS</LoadingText>
        </LoadingOverlay>
      </AnimatedCard>
    );
  }

  if (isProfilePrivate || hasNoGameHistory || recommendations.length === 0) {
    return (
      <AnimatedCard style={animationProps}>
        <CardTitle><Brain /> AI_ANALYSIS</CardTitle>
        <MessageContainer>
          <MessageIcon>
            <AlertTriangle />
          </MessageIcon>
          <MessageText>
            {isProfilePrivate ? "ACCESS_DENIED: Profile is private" :
             hasNoGameHistory ? "NO_DATA: Game history not found" :
             `NO_RECOMMENDATIONS_AVAILABLE: Check resource hub for ${currentGame.toUpperCase()}_GUIDES`}
          </MessageText>
        </MessageContainer>
      </AnimatedCard>
    );
  }

  return (
    <AnimatedCard style={animationProps}>
      <CardTitle><Brain /> AI_ANALYSIS</CardTitle>
      <RecommendationList>
        {recommendations.map((recommendation, index) => (
          <RecommendationCard
            key={index}
            style={cardSpring}
            $color={recommendation.color}
          >
            <RecommendationHeader>
              <RecommendationTitle>{recommendation.title}</RecommendationTitle>
              <ProcessingIndicator>
                <Cpu size={12} />
                ANALYZING
              </ProcessingIndicator>
            </RecommendationHeader>

            <RecommendationContent>{recommendation.content}</RecommendationContent>

            <Stats>
              {Object.entries(recommendation.stats).map(([label, value]) => (
                <Stat key={label}>
                  {label}: <strong>{value}</strong>
                </Stat>
              ))}
            </Stats>

            <Actions>
              <Button
                onClick={() => handleFeedback(index, true)}
                disabled={feedbackStates[index]}
              >
                <ThumbsUp size={16} />
                ACCEPT
              </Button>
              <Button
                onClick={() => handleFeedback(index, false)}
                disabled={feedbackStates[index]}
              >
                <ThumbsDown size={16} />
                REJECT
              </Button>
              {feedbackStates[index] && (
                <FeedbackMessage>
                  {feedbackStates[index] === 'helpful' ? 'FEEDBACK_RECORDED' : 'ANALYSIS_FLAGGED'}
                </FeedbackMessage>
              )}
              <ExpandButton onClick={() => toggleExpand(index)}>
                {expandedItems[index] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                {expandedItems[index] ? 'COLLAPSE' : 'EXPAND'}
              </ExpandButton>
            </Actions>

            {expandedItems[index] && (
              <DetailedAnalysis>
                {recommendation.details}
              </DetailedAnalysis>
            )}
          </RecommendationCard>
        ))}
      </RecommendationList>
    </AnimatedCard>
  );
}

export default Recommendations;
