import React, { useState, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { TrendingUp, Trophy, Skull, ChevronUp, ChevronDown, AlertTriangle } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { animated, useSpring } from 'react-spring';
import { themes } from '../theme';
import ErrorBoundary from './ErrorBoundary';

const CYBERPUNK_COLORS = themes.cyberpunk.colors;

// Keyframes remain the same
const scanlineAnimation = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const glowPulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// Updated Container with safe color access
const Container = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing.medium};
  position: relative;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.blue}33; // Using hex alpha

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: ${props => props.theme.gradients?.blue || `linear-gradient(45deg, ${props.theme.colors.blue} 0%, ${props.theme.colors.deepBlue} 100%)`};
    box-shadow: ${props => props.theme.neonShadow?.blue || `0 0 10px ${props.theme.colors.blue}`};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      transparent 50%,
      ${props => `${props.theme.colors.blue}0D`} 50%
    );
    background-size: 100% 4px;
    animation: ${scanlineAnimation} 4s linear infinite;
    pointer-events: none;
  }
`;

// Updated styled components with safe theme color access
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.large};
  position: relative;
  z-index: 1;

  &::before {
    content: 'PERFORMANCE_METRICS_V2.1';
    position: absolute;
    top: -20px;
    right: 0;
    font-family: ${props => props.theme.font};
    font-size: 0.7rem;
    color: ${props => props.theme.colors.blue};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const Title = styled.h2`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  font-size: 1.2rem;
  color: ${props => props.theme.colors.blue};
  margin: 0;
  text-transform: uppercase;
  font-family: ${props => props.theme.font};
  letter-spacing: 1px;

  svg {
    color: ${props => props.theme.colors.blue};
    filter: drop-shadow(0 0 2px ${props => props.theme.colors.blue});
  }
`;

const WinRateBadge = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  padding: ${props => props.theme.spacing.small} ${props => props.theme.spacing.medium};
  border-radius: 4px;
  font-weight: bold;
  background-color: ${props => props.$winRate >= 50
    ? `${props.theme.colors.success}1A`  // 10% opacity
    : `${props.theme.colors.pink}1A`
  };
  color: ${props => props.$winRate >= 50
    ? props.theme.colors.success
    : props.theme.colors.error
  };
  border: 1px solid ${props => props.$winRate >= 50
    ? props.theme.colors.success
    : props.theme.colors.error
  };
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;

  svg {
    animation: ${glowPulse} 2s infinite;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      ${props => `${props.theme.colors.blue}1A`},
      transparent
    );
    animation: shine 3s infinite;
  }

  @keyframes shine {
    from { left: -100%; }
    to { left: 100%; }
  }
`;

const ChartContainer = styled.div`
  height: 400px;
  margin-bottom: ${props => props.theme.spacing.large};
  position: relative;
  border: 1px solid ${props => props.theme.colors?.blue}33;
  border-radius: 4px;
  padding: 20px;
  background: ${props => props.theme.colors?.blue}0D;

  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line {
    stroke: ${props => props.theme.colors?.blue}1A;
  }

  .recharts-tooltip-wrapper {
    outline: none;
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${props => props.theme.spacing.medium};
  position: relative;

  &::before {
    content: 'ANALYZING_METRICS';
    position: absolute;
    top: -20px;
    left: 0;
    font-family: ${props => props.theme.font};
    font-size: 0.7rem;
    color: ${props => props.theme.colors.blue};
    text-transform: uppercase;
    letter-spacing: 1px;
  }
`;

const MetricButton = styled(animated.button)`
  padding: ${props => props.theme.spacing.medium};
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.$active
    ? `${props.theme.colors.blue}1A`
    : props.theme.cardBackground
  };
  border: 1px solid ${props => props.$active
    ? props.theme.colors.blue
    : `${props.theme.colors.blue}33`
  };
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      ${props => `${props.theme.colors.blue}1A`},
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    border-color: ${props => props.theme.colors.blue};
    box-shadow: ${props => props.theme.neonShadow?.blue};

    &::before {
      left: 100%;
    }
  }

  ${props => props.$active && `
    box-shadow: ${props.theme.neonShadow?.blue};
  `}
`;

const MetricLabel = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.textSecondary};
  margin-bottom: ${props => props.theme.spacing.small};
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const MetricValue = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${props => props.$active ? props.theme.colors.blue : props.theme.text};
  font-family: ${props => props.theme.font};
  text-shadow: ${props => props.$active ? `0 0 10px ${props.theme.colors.blue}80` : 'none'};
  transition: all 0.3s ease;
`;

const TooltipContainer = styled.div`
  background-color: ${props => props.theme.cardBackground};
  border: 1px solid ${props => props.theme.colors.blue};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing.medium};
  font-family: ${props => props.theme.font};
  box-shadow: ${props => props.theme.neonShadow?.blue};
`;

const TooltipHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing.small};
  font-size: 1.1rem;
  font-weight: bold;
  color: ${props => props.theme.colors.blue};
  margin-bottom: ${props => props.theme.spacing.medium};
  text-transform: uppercase;
  letter-spacing: 1px;

  svg {
    filter: drop-shadow(0 0 2px ${props => props.theme.colors.blue});
  }
`;

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.small};
  font-size: 0.9rem;
`;

const TooltipLabel = styled.span`
  color: ${props => props.$color || props.theme.textSecondary};
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const TooltipValue = styled.span`
  font-family: monospace;
  font-weight: 600;
  color: ${props => props.theme.text};
`;

const TooltipFooter = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
  padding-top: ${props => props.theme.spacing.medium};
  border-top: 1px solid ${props => props.theme.colors.blue};
  color: ${props => props.theme.textSecondary};
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const LoadingContainer = styled(Container)`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const LoadingSpinner = styled.div`
  width: 48px;
  height: 48px;
  border: 4px solid ${props => props.theme.colors.blue};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const NoDataMessage = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.textSecondary};
  text-align: center;
  font-family: ${props => props.theme.font};
  text-transform: uppercase;
  letter-spacing: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  svg {
    color: ${props => props.theme.colors.error};
    filter: drop-shadow(0 0 5px ${props => props.theme.colors.error});
  }
`;

const StatusIndicator = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: ${props => props.theme.font};
  font-size: 0.7rem;
  color: ${props => props.theme.colors.blue};
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 5px;

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue};
    animation: ${glowPulse} 2s infinite;
  }
`;

// Component implementation starting point
function PerformanceTrends({ playerData, currentGame, isProfilePrivate, hasNoGameHistory, loading }) {
  const [activeMetrics, setActiveMetrics] = useState(['kda', 'csPerMin', 'visionScore']);

  // Updated metrics colors to use theme values
  const metrics = useMemo(() => [
    {
      key: 'kda',
      name: 'KDA_RATIO',
      color: CYBERPUNK_COLORS?.pink || '#ff2a6d',
      formatter: value => value.toFixed(2)
    },
    {
      key: 'csPerMin',
      name: 'CS_PER_MIN',
      color: CYBERPUNK_COLORS?.blue || '#05d9e8',
      formatter: value => value.toFixed(1)
    },
    {
      key: 'visionScore',
      name: 'VISION_SCORE',
      color: CYBERPUNK_COLORS?.purple || '#7700ff',
      formatter: value => Math.round(value)
    }
  ], []);

  const processedData = useMemo(() => {
    if (!playerData?.matches?.length) return [];

    const currentTime = new Date().getTime();
    const oneMonthAgo = currentTime - (30 * 24 * 60 * 60 * 1000);

    return playerData.matches.map((match, index) => ({
      game: index + 1,
      champion: match.champion,
      role: match.role,
      win: match.win === 1,
      timestamp: match.gameTimestamp || oneMonthAgo + (index * (currentTime - oneMonthAgo) / playerData.matches.length),
      kills: match.kills,
      deaths: match.deaths,
      assists: match.assists,
      ...metrics.reduce((acc, metric) => {
        acc[metric.key] = parseFloat((match[metric.key] || 0).toFixed(2));
        return acc;
      }, {})
    }));
  }, [playerData, metrics]);

  const statistics = useMemo(() => {
    if (!processedData.length) return null;

    const wins = processedData.filter(match => match.win).length;
    return {
      winRate: (wins / processedData.length) * 100,
      averages: metrics.reduce((acc, metric) => {
        acc[metric.key] = parseFloat(
          (processedData.reduce((sum, match) => sum + (match[metric.key] || 0), 0) / processedData.length)
          .toFixed(2)
        );
        return acc;
      }, {})
    };
  }, [processedData, metrics]);

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    const matchData = processedData[label - 1];
    if (!matchData) return null;

    const formattedDate = new Date(matchData.timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return (
      <TooltipContainer>
        <TooltipHeader>
          {matchData.win ?
            <Trophy size={16} /> :
            <Skull size={16} />
          }
          {matchData.champion} - {matchData.win ? 'VICTORY' : 'DEFEAT'}
        </TooltipHeader>
        {payload.map((entry, index) => (
          <TooltipRow key={index}>
            <TooltipLabel $color={entry.color}>{entry.name}:</TooltipLabel>
            <TooltipValue>{entry.value.toFixed(2)}</TooltipValue>
          </TooltipRow>
        ))}
        <TooltipFooter>
          {matchData.role} // {formattedDate}
        </TooltipFooter>
      </TooltipContainer>
    );
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Title><TrendingUp /> PERFORMANCE_MATRIX</Title>
        <LoadingSpinner />
        <StatusIndicator>PROCESSING_DATA</StatusIndicator>
      </LoadingContainer>
    );
  }

  if (isProfilePrivate || hasNoGameHistory || !processedData.length) {
    return (
      <LoadingContainer>
        <Title><TrendingUp /> PERFORMANCE_MATRIX</Title>
        <NoDataMessage>
          <AlertTriangle size={48} />
          {isProfilePrivate ? "ACCESS_RESTRICTED: Profile is private" :
           hasNoGameHistory ? "NO_DATA: Game history not found" :
           "NO_PERFORMANCE_DATA_AVAILABLE"}
        </NoDataMessage>
      </LoadingContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Title><TrendingUp /> PERFORMANCE_MATRIX</Title>
        {statistics && (
          <WinRateBadge $winRate={statistics.winRate}>
            {statistics.winRate >= 50 ? <Trophy size={16} /> : <Skull size={16} />}
            {statistics.winRate.toFixed(1)}% WIN_RATE
          </WinRateBadge>
        )}
      </Header>
      
      <ChartContainer>
        <ErrorBoundary>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={processedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={`${CYBERPUNK_COLORS.blue}1A`}
              />
              <XAxis
                dataKey="game"
                stroke={CYBERPUNK_COLORS.blue}
                tickFormatter={value => `GAME_${value}`}
                style={{
                  fontFamily: "'JetBrains Mono', monospace",
                  fontSize: '0.8rem',
                  textTransform: 'uppercase'
                }}
              />
              <YAxis
                stroke={CYBERPUNK_COLORS.blue}
                style={{
                  fontFamily: "'JetBrains Mono', monospace",
                  fontSize: '0.8rem'
                }}
              />
              <Tooltip
                content={<CustomizedTooltip />}
                cursor={{ stroke: CYBERPUNK_COLORS.blue, strokeWidth: 1 }}
                wrapperStyle={{ outline: 'none' }}
              />
              <Legend
                wrapperStyle={{
                  fontFamily: "'JetBrains Mono', monospace",
                  fontSize: '0.8rem',
                  textTransform: 'uppercase'
                }}
                onClick={({ dataKey }) => {
                  setActiveMetrics(prev =>
                    prev.includes(dataKey)
                      ? prev.filter(k => k !== dataKey)
                      : [...prev, dataKey]
                  );
                }}
              />
              {metrics.map((metric) => (
                activeMetrics.includes(metric.key) && (
                  <Line
                    key={metric.key}
                    type="monotone"
                    dataKey={metric.key}
                    stroke={metric.color}
                    name={metric.name}
                    dot={{
                      r: 4,
                      strokeWidth: 2,
                      fill: CYBERPUNK_COLORS.cardBackground
                    }}
                    activeDot={{
                      r: 6,
                      strokeWidth: 0,
                      fill: metric.color,
                      style: { filter: `drop-shadow(0 0 3px ${metric.color})` }
                    }}
                    strokeWidth={2}
                  />
                )
              ))}
            </LineChart>
          </ResponsiveContainer>
        </ErrorBoundary>
      </ChartContainer>

      <MetricsGrid>
        {metrics.map((metric) => {
          const isActive = activeMetrics.includes(metric.key);
          return (
            <MetricButton
              key={metric.key}
              $active={isActive}
              onClick={() => {
                setActiveMetrics(prev =>
                  prev.includes(metric.key)
                    ? prev.filter(k => k !== metric.key)
                    : [...prev, metric.key]
                );
              }}
            >
              <MetricLabel>{metric.name}</MetricLabel>
              <MetricValue $active={isActive}>
                {statistics?.averages[metric.key]
                  ? metric.formatter(statistics.averages[metric.key])
                  : 'N/A'}
              </MetricValue>
            </MetricButton>
          );
        })}
      </MetricsGrid>

      <StatusIndicator>
        MATRIX_ACTIVE
      </StatusIndicator>
    </Container>
  );
}

export default PerformanceTrends;
